
/**
 * Module dependencies
 */

import { Box, Svg, color, units } from '@untile/react-components';
import { theme } from 'styled-tools';
import { useVerticalBarContext } from 'src/context/vertical-bar/context';
import LanguageSwitcher from 'src/components/language-switcher';
import React, { ReactElement } from 'react';
import iconDownload from 'src/assets/svg/download.svg';
import styled from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  brochureFile?: string | null
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('white')};
  border-right: 1px solid ${color('grey100')};
  bottom: 0;
  display: grid;
  grid-gap: ${units(2)};
  grid-template-areas:
    'brochure'
    'title'
    'langs';
  grid-template-rows: max-content 1fr max-content;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: ${theme('dimensions.verticalBar')}px;
  z-index: ${theme('zIndex.verticalBar')};
`;

/**
 * `StyledSvg` styled component.
 */

const StyledSvg = styled(Svg)`
  transition: transform ${theme('animations.defaultTransition')};
  will-change: transform;
`;

/**
 * `BrochureLink` styled component.
 */

const BrochureLink = styled.a`
  grid-area: brochure;
  padding-top: ${units(2)};
  text-decoration: none;
  width: max-content;

  &:focus,
  &:hover {
    ${StyledSvg} {
      transform: translateY(4px);
    }
  }
`;

/**
 * `BrochureLabel` styled component.
 */

const BrochureLabel = styled.div`
  align-items: center;
  color: ${color('black')};
  display: flex;
  flex-direction: column;
  grid-row-gap: ${units(1)};
  justify-content: center;
  position: relative;

  div {
    ${theme('typography.styles.small')}

    font-weight: 400;
    letter-spacing: 4.17px;
    text-transform: uppercase;
    white-space: nowrap;
    writing-mode: vertical-rl;
  }
`;

/**
 * `BrochureWrapper` styled component.
 */

const Title = styled.div`
  align-self: center;
  grid-area: title;
  width: max-content;

  span {
    ${theme('typography.styles.p')}

    font-weight: 300;
    letter-spacing: 9px;
    text-transform: lowercase;
    transform: rotate(180deg);
    white-space: nowrap;
    writing-mode: vertical-rl;
  }
`;

/**
 * `VerticalBar` component.
 */

const VerticalBar = ({ brochureFile }: Props): ReactElement => {
  const { translate } = useTranslate();
  const { title } = useVerticalBarContext();

  return (
    <Wrapper>
      {brochureFile && (
        <BrochureLink
          href={brochureFile}
          rel={'noopener'}
          target={'_blank'}
        >
          <BrochureLabel>
            <div>
              {translate('common:verticalBar.brochure')}
            </div>

            <StyledSvg
              icon={iconDownload}
              size={units(2.5)}
            />
          </BrochureLabel>
        </BrochureLink>
      )}

      <Title>
        {title && (
          <span>
            {title}
          </span>
        )}
      </Title>

      <Box gridArea={'langs'}>
        <LanguageSwitcher />
      </Box>
    </Wrapper>
  );
};

/**
 * Export `VerticalBar` component.
 */

export default VerticalBar;
