
/**
 * Module dependencies.
 */

import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/**
 * `Result` Props.
 */

type Result = [
  boolean,
  Dispatch<SetStateAction<boolean>>
];

/**
 * `Options` type.
 */

type Options = {
  initialState: boolean
};

/**
 * `useDetectOutsideClick` hook.
 */

function useDetectOutsideClick(ref, { initialState }: Options): Result {
  const [active, setActive] = useState<boolean>(initialState);

  useEffect(() => {
    const onClick = ({ target }) => {
      if (ref && ref?.current !== null && !ref?.current?.contains(target)) {
        setActive(!active);
      }
    };

    if (active) {
      window.addEventListener('click', onClick);
    }

    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [active, ref]);

  return [active, setActive];
}

/**
 * Export `useDetectOutsideClick` hook.
 */

export default useDetectOutsideClick;
