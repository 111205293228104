
/**
 * Module dependencies.
 */

import { useRouter } from 'next/router';
import React, { ReactElement, ReactNode, useCallback, useEffect } from 'react';

/**
 * Constants.
 */

const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode
};

/**
 * `Event` type.
 */

type Event = { [key: string]: any };

/**
 * `Url` type.
 */

type Url = string;

/**
 * `GTMHandleProps` type.
 */

type GTMHandleProps = {
  onEvent: (event: Event) => void,
  onPageView: (url: Url) => void
};

/**
 * Export `useGoogleTagManager` hook.
 */

export function useGoogleTagManager(): GTMHandleProps {
  const handlePageView = useCallback((url: Url) => {
    if (isProduction && googleTagManagerId) {
      window.dataLayer.push({
        event: 'pageview',
        page: url
      });
    }
  }, []);

  const handleEvent = useCallback((event: Event) => {
    if (isProduction && googleTagManagerId) {
      window.dataLayer.push(event);
    }
  }, []);

  return {
    onEvent: handleEvent,
    onPageView: handlePageView
  };
}

/**
 * `GoogleTagManager` component.
 */

const GoogleTagManager = ({ children }: Props): ReactElement => {
  const router = useRouter();
  const { onPageView } = useGoogleTagManager();

  useEffect(() => {
    if (!googleTagManagerId) {
      return;
    }

    router.events.on('routeChangeComplete', onPageView);

    return () => {
      router.events.off('routeChangeComplete', onPageView);
    };
  }, [onPageView, router]);

  return (
    <>
      {children}
    </>
  );
};

/**
 * Export `GoogleTagManager` component.
 */

export default GoogleTagManager;

