
/**
 * Module dependencies.
 */

import { RouteName } from 'i18n-routes';
import { routes } from 'src/core/routes';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import template from 'lodash/template';

/**
 * `RouteInterpolations` type.
 */

type RouteInterpolations = {
  [key: string]: string
};

/**
 * Template options.
 */

const templateOptions = {
  interpolate: /:([\s\S]+?)\b/g
};

/**
 * Export `routeTemplate`.
 */

export function routeTemplate(route: string, interpolations: RouteInterpolations): string {
  return template(route, templateOptions)(interpolations);
}

/**
 * Export `useLocalizedRoute` hook.
 */

export function useLocalizedRoute() {
  const { locale } = useRouter();
  const handleRouteResolver = useCallback((routeName: RouteName, interpolations?: RouteInterpolations) => {
    const route = routes[locale][routeName];

    if (!interpolations) {
      return route;
    }

    return template(route, templateOptions)(interpolations);
  }, [locale]);

  return handleRouteResolver;
}
