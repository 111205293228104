
/**
 * Module dependencies.
 */

import { useLocalizedRoute } from 'src/core/utils/routes';
import { useMemo } from 'react';
import useTranslate from 'src/hooks/use-translate';

/**
 * Export `NavbarLink` type.
 */

export type NavbarLink = {
  href?: string | null,
  label: string
};

/**
 * Export `useNavbarLinks`.
 */

export function useNavbarLinks(): NavbarLink[] {
  const routeResolver = useLocalizedRoute();
  const { translate } = useTranslate();
  const navbarLinks = useMemo(() => {
    return [{
      href: routeResolver('company'),
      label: translate('common:menu.labels.company')
    }, {
      href: routeResolver('projects'),
      label: translate('common:menu.labels.projects')
    }, {
      href: routeResolver('news'),
      label: translate('common:menu.labels.news')
    }, {
      href: routeResolver('about'),
      label: translate('common:menu.labels.about')
    }, {
      href: routeResolver('contacts'),
      label: translate('common:menu.labels.contacts')
    }];
  }, [routeResolver, translate]);

  return navbarLinks;
}
