
/**
 * Export `dimensions`.
 */

export const dimensions = {
  footerHeight: 280,
  footerHeightMobile: 616,
  navbarHeight: 85,
  navbarHeightMobile: 80,
  verticalBar: 36
};
