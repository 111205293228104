
/**
 * Module dependencies.
 */

import i18nRoutes, { I18nRoutes } from 'i18n-routes';

/**
 * Export `routes`.
 */

export const routes: I18nRoutes = i18nRoutes;
