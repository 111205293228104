
/**
 * Module dependencies.
 */

import {
  Svg,
  Type,
  color,
  isExternalUrl,
  states,
  units
} from '@untile/react-components';

import { ifProp, theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useNavbarLinks } from 'src/core/content-config/navbar';
import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import iconDownload from 'src/assets/svg/download.svg';
import map from 'lodash/map';
import styled, { css } from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  brochureFile?: string | null,
  visible: boolean
};

/**
 * `MenuWrapper` styled component.
 */

const MenuWrapper = styled.div<{ visible?: boolean }>`
  display: grid;
  grid-gap: ${units(3)};
  grid-template-areas:
    'menu     menu'
    'brochure langs';
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: max-content 1fr;
  height: 100%;
  opacity: ${ifProp('visible', 1, 0)};
  padding: ${units(4)} 0;
  pointer-events: ${ifProp('visible', 'auto', 'none')};
  transform: translateX(${ifProp('visible', '0', '100%')});
  transition: ${theme('animations.fastTransition')};
  transition-delay: 0.15s;
  transition-property: opacity, transform;
  width: 100%;

  ${ifProp('visible', css`
    transition-delay: 0.6s;
  `)}
`;

/**
 * `MenuList` styled component.
 */

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: menu;
  grid-gap: ${units(3)};
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Small)`
  font-weight: 400;
  letter-spacing: 3.57px;
  transition: font-weight ${theme('animations.defaultTransition')};
  will-change: font-weight;
`;

/**
 * `ListItemLink` styled component.
 */

const ListItemLink = styled.a.attrs(({ href }) => ({
  as: href && !isExternalUrl(href) && RouterLink || 'a'
}))<{ visible?: boolean }>`
  color: ${color('textColor')};
  cursor: pointer;
  line-height: 24px;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.3s ease;

  ${states.action`
    outline: none;
  `}

  &:focus,
  &:hover {
    ${Label} {
      font-weight: 700;
    }
  }
`;

/**
 * `BrochureLink` styled component.
 */

const BrochureLink = styled.a`
  align-self: flex-end;
  grid-area: brochure;
  text-decoration: none;
  width: max-content;
`;

/**
 * `BrochureLabel` styled component.
 */

const BrochureLabel = styled.div`
  align-items: center;
  color: ${color('black')};
  display: flex;
  flex-direction: column;
  grid-gap: ${units(1)};
  justify-content: center;
  position: relative;

  div {
    ${theme('typography.styles.small')}

    font-weight: 400;
    letter-spacing: 4.17px;
    text-transform: uppercase;
    white-space: nowrap;
    writing-mode: vertical-rl;
  }
`;

/**
 * `ListLang` styled component.
 */

const ListLang = styled.ul`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  grid-area: langs;
  grid-gap: ${units(1)};
  justify-self: flex-end;
`;

/**
 * `ListLangItemLink` styled component.
 */

const ListLangItemLink = styled(RouterLink)<{ active: boolean }>`
  ${theme('typography.styles.small')}

  color: ${color.transparentize('black', 0.5)};
  cursor: pointer;
  font-weight: 400;
  letter-spacing: 2px;
  padding: ${units(1)} 0;
  text-transform: uppercase;
  transition: color ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    color: ${color('black')};
  }

  ${ifProp('active', css`
    color: ${color('black')};
    cursor: default;
    pointer-events: none;
  `)}
`;

/**
 * `Menu` component.
 */

const Menu = ({ brochureFile, visible }: Props): ReactElement => {
  const { translate } = useTranslate();
  const { locale, locales } = useRouter();
  const routeResolver = useLocalizedRoute();
  const navbarLinks = useNavbarLinks();

  return (
    <MenuWrapper visible={visible}>
      <MenuList>
        {map(navbarLinks, ({ href, label }) => href && (
          <ListItemLink
            href={href}
            key={label}
            // @ts-ignore
            locale={locale}
          >
            <Label>
              {label}
            </Label>
          </ListItemLink>
        ))}
      </MenuList>

      {brochureFile && (
        <BrochureLink
          href={brochureFile}
          rel={'noopener'}
          target={'_blank'}
        >
          <BrochureLabel>
            <div>
              {translate('common:verticalBar.brochure')}
            </div>

            <Svg
              icon={iconDownload}
              size={units(2.5)}
            />
          </BrochureLabel>
        </BrochureLink>
      )}

      <ListLang>
        {map(locales, language => (
          <li key={language}>
            <ListLangItemLink
              active={language === locale}
              href={routeResolver('home')}
              locale={language}
            >
              {language}
            </ListLangItemLink>
          </li>
        ))}
      </ListLang>
    </MenuWrapper>
  );
};

/**
 * Export `Menu` component.
 */

export default Menu;
