
/**
 * Palette.
 */

const palette = {
  black: '#000000',
  green: '#00a79e',
  grey100: '#eaeaea',
  grey500: '#797979',
  white: '#ffffff'
};

/**
 * Export `colors`.
 */

export const colors = {
  ...palette,
  primary: palette.black,
  textColor: palette.black
};
