
/**
 * Module dependencies.
 */

import { Type, color, media, units } from '@untile/react-components';
import { parseCookies, setCookie } from 'nookies';
import { switchProp, theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useRouter } from 'next/router';
import Button from 'src/components/core/buttons/button';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import Trans from 'next-translate/Trans';
import Transition from 'react-transition-group/Transition';
import styled, { css } from 'styled-components';
import useTranslate from 'src/hooks/use-translate';

/**
 * Contansts.
 */

const cookieName = 'accept-cookies';
const duration = 500;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ transitionState: any }>`
  background-color: ${color('white')};
  box-shadow: 0 10px 20px 0 ${color.transparentize('black', 0.14)};
  left: ${units(2.5)};
  opacity: 0;
  position: fixed;
  right: ${units(2.5)};
  top: 50%;
  transform: translateY(-50%);
  transition: ${theme('animations.defaultTransition')};
  transition-delay: 0s 0.35s;
  transition-property: transform, opacity;
  z-index: ${theme('zIndex.cookiesBar')};

  ${media.min('ms')`
    left: ${units(10)};
    right: ${units(10)};
  `}

  ${media.min('md')`
    bottom: auto;
    left: 50%;
    max-width: 630px;
    right: auto;
    transform: translate(-50%, -50%);
  `}

  ${switchProp('transitionState', {
    entered: css`
      opacity: 1;
    `,
    entering: css`
      opacity: 1;
    `,
    exited: css`
      opacity: 0;
    `,
    exiting: css`
      opacity: 0;
    `
  })}
`;

/**
 * `Message` styled component.
 */

const Message = styled.div`
  color: ${color('black')};
  display: flex;
  flex-direction: column;
  grid-gap: ${units(2)};
  padding: ${units(2.5)};

  ${media.min('ms')`
    align-items: center;
    flex-direction: row;
    grid-gap: ${units(5)};
    justify-content: space-between;
  `}
  
  ${media.min('lg')`
    grid-gap: ${units(9)};
    padding: ${units(2.5)} ${units(7.5)};
  `}
`;

/**
 * `StyledRouterLink` styled component.
 */

const StyledRouterLink = styled(RouterLink)`
  color: ${color('black')};
  text-decoration: underline;
  transition: color ${theme('animations.defaultTransition')};
  white-space: nowrap;
`;

/**
 * `Description` component.
 */

const Description = (): ReactElement => {
  const { translate } = useTranslate();
  const { locale } = useRouter();
  const routeResolver = useLocalizedRoute();

  return (
    <div>
      <Type.Small size={'xxSmall'}>
        {translate('common:cookiesBar.description1')}

        <br />

        <Trans
          components={[
            <StyledRouterLink
              href={routeResolver('cookiesPolicy')}
              key={'legal-link'}
              locale={locale}
            >
              {translate('common:cookiesBar.legalLink')}
            </StyledRouterLink>
          ]}
          i18nKey={'common:cookiesBar.description2'}
        />
      </Type.Small>
    </div>
  );
};

/**
 * `CookiesBar` component.
 */

const CookiesBar = (): ReactElement | null => {
  const { translate } = useTranslate();
  const cookies = parseCookies();
  const [visible, setVisible] = useState<boolean>(false);
  const handleAcceptTerms = useCallback(() => {
    setVisible(false);
    setCookie(null, cookieName, 'true', {
      maxAge: 30 * 24 * 60 * 60,
      path: '/'
    });
  }, []);

  useEffect(() => {
    if (!cookies[cookieName]) {
      setVisible(true);
    }
  }, [cookies]);

  return (
    <Transition
      in={visible}
      mountOnEnter
      timeout={duration}
      unmountOnExit
    >
      {state => (
        <Wrapper transitionState={state}>
          <Message>
            <Description />

            <Button
              onClick={handleAcceptTerms}
              variant={'outline'}
            >
              {translate('common:actions.acceptCookies')}
            </Button>
          </Message>
        </Wrapper>
      )}
    </Transition>
  );
};

/**
 * Export `CookiesBar` component.
 */

export default CookiesBar;
