
/**
 * Export `i18nRoutes`.
 */

module.exports = {
  de: {
    about: '/uber-by-steel',
    company: '/begleitung',
    contacts: '/kontakt',
    cookiesPolicy: '/legal/cookie-richtlinie',
    home: '/',
    news: '/nachrichten',
    newsDetail: '/nachrichten/:slug',
    privacyPolicy: '/legal/datenschutzpolitik',
    projects: '/projekte',
    projectsDetail: '/projekte/:slug',
    termsConditions: '/legal/allgemeine-geschaftsbedingungen'
  },
  en: {
    about: '/about-us',
    company: '/company',
    contacts: '/contact-us',
    cookiesPolicy: '/legal/cookies-policy',
    home: '/',
    news: '/news',
    newsDetail: '/news/:slug',
    privacyPolicy: '/legal/privacy-policy',
    projects: '/projects',
    projectsDetail: '/projects/:slug',
    termsConditions: '/legal/terms-conditions'
  },
  fr: {
    about: '/qui-sommes-nous',
    company: '/entreprise',
    contacts: '/contacts',
    cookiesPolicy: '/legal/politique-cookies',
    home: '/',
    news: '/des-nouvelles',
    newsDetail: '/des-nouvelles/:slug',
    privacyPolicy: '/legal/politique-confidentialite',
    projects: '/projets',
    projectsDetail: '/projets/:slug',
    termsConditions: '/legal/termes-et-conditions'
  },
  pt: {
    about: '/sobre-nos',
    company: '/empresa',
    contacts: '/contactos',
    cookiesPolicy: '/legal/politica-de-cookies',
    home: '/',
    news: '/noticias',
    newsDetail: '/noticias/:slug',
    privacyPolicy: '/legal/politica-de-privacidade',
    projects: '/projetos',
    projectsDetail: '/projetos/:slug',
    termsConditions: '/legal/termos-e-condicoes'
  }
};
