
/**
 * Module dependencies.
 */

import applyCaseMiddleware from 'axios-case-converter';
import axios from 'axios';
import camelCase from 'lodash/camelCase';
import qs from 'qs';

/**
 * Export `acceptLanguageHeader`.
 */

export function acceptLanguageHeader(locale: string) {
  return {
    'Accept-Language': locale
  };
}

/**
 * Options.
 */

const options = {
  caseFunctions: {
    camel: camelCase
  }
};

/**
 * Export `request.
 */

export const request = applyCaseMiddleware(axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  paramsSerializer: params => qs.stringify(params)
}), options);

/**
 * Export `handleRequestError`.
 */

export function handleRequestError(error: any) {
  return error?.response ?? error;
}
