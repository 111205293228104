
/**
 * Default transition.
 */

const defaultTransition = '0.5s ease';

/**
 * Fast transition.
 */

const fastTransition = '0.2s ease';

/**
 * Ease out quad transition.
 */

const easeOutQuadTransition = '0.5s cubic-bezier(0.4, 0, 0.2, 1)';

/**
 * Export `animations`.
 */

export const animations = {
  defaultTransition,
  easeOutQuadTransition,
  fastTransition
};
