
/**
 * Export `i18n` configuration.
 */

module.exports = {
  defaultLocale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
  loadLocaleFrom: (locale, namespace) => {
    return Promise.resolve(
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      require(`./public/static/locales/${locale}/${namespace}.json`)
    );
  },
  locales: process.env.NEXT_PUBLIC_LOCALES.split(','),
  pages: {
    '*': ['common', 'footer', 'metatags'],
    '/contactos': ['contacts'],
    '/empresa': ['company'],
    '/legal/politica-de-cookies': ['cookies-policy'],
    '/legal/politica-de-privacidade': ['privacy-policy'],
    '/legal/termos-e-condicoes': ['terms-conditions'],
    'rgx:^/projetos': ['projects']
  }
};
