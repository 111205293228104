
/**
 * Module dependencies.
 */

import { ifProp, prop, theme } from 'styled-tools';
import { units } from '@untile/react-components/dist/styles';
import React, {
  FC,
  ReactElement,
  ReactNode,
  forwardRef,
  useCallback,
  useRef
} from 'react';

import styled, { css } from 'styled-components';
import useDetectOutsideClick from 'src/hooks/use-detect-outside-click';

/**
 * `ButtonProps` type.
 */

type ButtonProps = {
  'aria-expanded': boolean,
  'aria-haspopup': boolean,
  onClick: () => void,
  role: string,
  tabIndex: number
};

/**
 * Export `DropdownProps` interface.
 */

export interface DropdownProps {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  ref?: any;
  renderButton: (props: ButtonProps) => ReactElement;
  width?: string;
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

/**
 * `Menu` styled component.
 */

const Menu = styled.div<Pick<DropdownProps, 'width'> & { isOpen: boolean }>`
  align-items: center;
  bottom: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: ${units(1)};
  left: 50%;
  opacity: 0;
  overflow-y: auto;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, ${units(3)});
  transition: ${theme('animations.defaultTransition')};
  transition-property: opacity, transform, visibility;
  visibility: hidden;
  width: ${prop('width', '230px')};

  ${ifProp('isOpen', css`
    opacity: 1;
    pointer-events: auto;
    transform: translate(-50%, 0);
    visibility: visible;
  `)}
`;

/**
 * `Dropdown` component.
 */

const Dropdown: FC<DropdownProps> = forwardRef<any, DropdownProps>((props: DropdownProps, ref: any): ReactElement => {
  const { children, className, isOpen, renderButton, width } = props;
  const menuRef = useRef<HTMLDivElement>();
  const [active, setActive] = useDetectOutsideClick(ref, { initialState: false });
  const handleClick = useCallback(() => {
    setActive(!active);
  }, [active, setActive]);

  return (
    <Wrapper
      className={className}
      ref={ref}
    >
      {renderButton({
        'aria-expanded': isOpen,
        'aria-haspopup': true,
        onClick: handleClick,
        role: 'button',
        tabIndex: 0
      })}

      <Menu
        isOpen={isOpen}
        ref={menuRef}
        role={'menu'}
        tabIndex={-1}
        width={width}
      >
        {children}
      </Menu>
    </Wrapper>
  );
});

/**
 * `Dropdown` display name.
 */

Dropdown.displayName = 'Dropdown';

/**
 * Export `Dropdown` component.
 */

export default Dropdown;
