
/**
 * Module dependencies.
 */

import { css } from 'styled-components';
import { setFontStyle } from '@untile/react-components';
import { switchProp } from 'styled-tools';

/**
 * Font families.
 */

const fontFamily = {
  sansSerif: 'Helvetica Neue, sans-serif'
};

/**
 * Type sizes.
 */

const typeSizes = {
  display: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 70,
    fontSizeMax: 70,
    fontSizeMin: 40,
    fontWeight: 700,
    letterSpacing: 8.75,
    lineHeight: 70
  },
  h1: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 50,
    fontSizeMax: 50,
    fontSizeMin: 30,
    fontWeight: 700,
    letterSpacing: 12.5,
    lineHeight: 50
  },
  h2: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: 5,
    lineHeight: 20
  },
  h3: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: 2.67,
    lineHeight: 20
  },
  paragraph: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.14,
    lineHeight: 22
  },
  paragraphLarge: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 16,
    fontWeight: 300,
    letterSpacing: 0.12,
    lineHeight: 25
  },
  paragraphMedium: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 12,
    fontWeight: 300,
    letterSpacing: 0.3,
    lineHeight: 15
  },
  paragraphSmall: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 10,
    fontWeight: 400,
    letterSpacing: 0.12,
    lineHeight: 22
  },
  small: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 10,
    fontWeight: 300,
    letterSpacing: 0.12,
    lineHeight: 12
  },
  subtitle1: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 10,
    fontWeight: 300,
    letterSpacing: 7.5,
    lineHeight: 50
  },
  subtitle2: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 10,
    fontWeight: 300,
    letterSpacing: 7.5,
    lineHeight: 20
  },
  xSmall: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 10,
    fontWeight: 400,
    letterSpacing: 2,
    lineHeight: 10
  },
  xxSmall: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 9,
    fontWeight: 400,
    letterSpacing: 3.21,
    lineHeight: 10
  }
};

/**
 * Reset font settings.
 */

const resetFontSettings = css`
  font-feature-settings: normal;
`;

/**
 * `display` style.
 */

const display = css`
  ${setFontStyle(typeSizes.display)}
  ${resetFontSettings}
`;

/**
 * `h1` style.
 */

const h1 = css`
  ${setFontStyle(typeSizes.h1)}
  ${resetFontSettings}
`;

/**
 * `h2` style.
 */

const h2 = css`
  ${setFontStyle(typeSizes.h2)}
  ${resetFontSettings}
`;

/**
 * `h3` style.
 */

const h3 = css`
  ${setFontStyle(typeSizes.h3)}
  ${resetFontSettings}
`;

/**
 * `paragraph` style.
 */

const paragraph = css`
  ${switchProp('size', {
    large: css`
      ${setFontStyle(typeSizes.paragraphLarge)}
    `,
    medium: css`
      ${setFontStyle(typeSizes.paragraphMedium)}
    `,
    small: css`
      ${setFontStyle(typeSizes.paragraphSmall)}
    `
  }, css`${setFontStyle(typeSizes.paragraph)}`)}

  ${resetFontSettings}
`;

/**
 * `small` style.
 */

const small = css`
  ${switchProp('size', {
    xSmall: css`
      ${setFontStyle(typeSizes.xSmall)}
    `,
    xxSmall: css`
      ${setFontStyle(typeSizes.xxSmall)}
    `
  }, css`${setFontStyle(typeSizes.small)}`)}

  ${resetFontSettings}
`;

/**
 * `subtitle1` style.
 */

const subtitle1 = css`
  ${setFontStyle(typeSizes.subtitle1)}
  ${resetFontSettings}
`;

/**
 * `subtitle2` style.
 */

const subtitle2 = css`
  ${setFontStyle(typeSizes.subtitle2)}
  ${resetFontSettings}
`;

/**
 * Export types to be generated.
 */

const styles = {
  h1,
  h2,
  h3,
  p: paragraph, // eslint-disable-line id-length
  small
};

/**
 * Export `type`.
 */

export const typography = {
  fontFamily,
  otherStyles: {
    display,
    subtitle1,
    subtitle2
  },
  styles
};
