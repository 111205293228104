
/**
 * Module dependencies.
 */

import { Svg, color, units } from '@untile/react-components';
import { theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useRouter } from 'next/router';
import Dropdown from 'src/components/language-switcher/dropdown';
import React, { ReactElement, useMemo, useRef } from 'react';
import RouterLink from 'src/components/core/links/router-link';
import iconArrowUp from 'src/assets/svg/arrow-up.svg';
import map from 'lodash/map';
import styled from 'styled-components';
import useDetectOutsideClick from 'src/hooks/use-detect-outside-click';

/**
 * `Props` type.
 */

type Props = {
  className?: string
}

/**
 * `ListItemLink` styled component.
 */

const ListItemLink = styled(RouterLink)`
  ${theme('typography.styles.small')}

  color: ${color.transparentize('black', 0.5)};
  cursor: pointer;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  transition: color ${theme('animations.defaultTransition')};

  &:focus,
  &:hover {
    color: ${color('black')};
  }
`;

/**
 * `ButtonLang` styled component.
 */

const ButtonLang = styled.button`
  ${theme('typography.styles.small')}

  -webkit-tap-highlight-color: transparent;
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  letter-spacing: 2px;
  outline: none;
  padding: ${units(1.5)} 0;
  text-transform: uppercase;
`;

/**
 * `LanguageSwitcher` component.
 */

const LanguageSwitcher = ({ className }: Props): ReactElement => {
  const { locale, locales } = useRouter();
  const routeResolver = useLocalizedRoute();
  const activeLang = useMemo(() => {
    return map(locales, language => {
      if (language === locale) {
        return language;
      }
    });
  }, [locale, locales]);

  const dropdownRef = useRef();
  const [isOpen, setOpen] = useDetectOutsideClick(dropdownRef, {
    initialState: false
  });

  return (
    <Dropdown
      className={className}
      isOpen={isOpen}
      ref={dropdownRef}
      renderButton={buttonProps => (
        <ButtonLang
          {...buttonProps}
          onClick={() => setOpen(!isOpen)}
        >
          {activeLang}

          <Svg
            icon={iconArrowUp}
            size={units(0.5)}
          />
        </ButtonLang>
      )}
      width={'36px'}
    >
      {map(locales, language => !(language === locale) && (
        <ListItemLink
          href={routeResolver('home')}
          key={language}
          locale={language}
        >
          <span onClick={() => setOpen(!isOpen)}>
            {language}
          </span>
        </ListItemLink>
      ))}
    </Dropdown>
  );
};

/**
 * Export `LanguageSwitcher` component.
 */

export default LanguageSwitcher;
