
/**
 * Export `zIndex`.
 */

export const zIndex = {
  cookiesBar: 102,
  dropdown: 99,
  filter: 104,
  hamburgerMenu: 104,
  modal: 105,
  navbar: 102,
  sidebar: 104,
  verticalBar: 103
};
