
/**
 * Module dependencies.
 */

import {
  acceptLanguageHeader,
  handleRequestError,
  request
} from 'src/core/utils/requests';

import { getApiEndpoint } from 'src/core/utils/api-url-resolver';

/**
 * `Query` type.
 */

type Query = {
  locale: string,
  ssr?: boolean
};

/**
 * Export `fetchContactPage`.
 */

export async function fetchContactPage(options?: Query) {
  const endpoint = getApiEndpoint('getContactPage');

  try {
    const { data } = await request.get(endpoint, {
      headers: acceptLanguageHeader(options.locale)
    });

    return data?.data;
  } catch (error) {
    if (!options?.ssr) {
      throw handleRequestError(error);
    }
  }
}
