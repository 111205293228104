
/**
 * Module dependencies.
 */

import { Fill, color, units } from '@untile/react-components';
import { ifProp, theme } from 'styled-tools';
import Container from 'src/components/core/layout/container';
import Menu from './menu';
import React, { ReactElement, useEffect, useState } from 'react';
import router from 'next/router';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  brochureFile?: string | null,
  isOpen: boolean
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Fill)<{
  closeImmediately: boolean,
  visible: boolean
}>`
  background-color: ${color('white')};
  min-height: calc(100vh - ${theme('dimensions.navbarHeightMobile')}px);
  opacity: 1;
  overflow: hidden;
  padding-top: ${theme('dimensions.navbarHeightMobile')}px;
  position: fixed;
  transform: translateX(100%);
  transform-origin: left center;
  transition: transform ${theme('animations.easeOutQuadTransition')};
  transition-delay: 0.25s;

  ${ifProp('visible', css`
    transform: translateX(0);
    transition-delay: 0.25s;
  `, css`
    pointer-events: none;
  `)}

  ${ifProp('closeImmediately', css`
    opacity: 0;
    pointer-events: none;
    transform: translateX(100%);
  `)}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container).attrs({ fluid: true })`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  scroll-snap-points-y: repeat(${units(10)});
  scroll-snap-type: y mandatory;
  z-index: ${theme('zIndex.sidebar')};
`;

/**
 * `Sidebar` component.
 */

const Sidebar = ({ brochureFile, isOpen }: Props): ReactElement => {
  const [closeImmediately, setCloseImmediately] = useState<boolean>(false);

  useEffect(() => {
    const handleRouteChange = () => {
      setTimeout(() => {
        setCloseImmediately(true);
      }, 400);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      setCloseImmediately(false);
    }
  }, [isOpen]);

  return (
    <Wrapper
      closeImmediately={closeImmediately}
      visible={isOpen}
    >
      <StyledContainer>
        <Menu
          brochureFile={brochureFile}
          visible={isOpen}
        />
      </StyledContainer>
    </Wrapper>
  );
};

/**
 * Export `Sidebar` component.
 */

export default Sidebar;
