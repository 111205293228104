
/**
 * Module dependencies.
 */

import { color, media } from '@untile/react-components';
import { createGlobalStyle } from 'styled-components';
import { theme } from 'styled-tools';
import { typography } from 'src/styles/type';

/**
 * Export `GlobalStyle` component.
 */

export default createGlobalStyle`
  body {
    ${typography.styles.p}

    color: ${color('textColor')};
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  section.fp-section {
    padding-top: ${theme('dimensions.navbarHeightMobile')}px;

    ${media.min('md')`
      padding-top: ${theme('dimensions.navbarHeight')}px;
    `}
  }
`;
