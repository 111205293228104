
/**
 * Module dependencies.
 */

import {
  Svg,
  color,
  media,
  states,
  units,
  useBodyScroll,
  useBreakpoint
} from '@untile/react-components';

import { Type } from 'src/components/core/typography';
import { theme } from 'styled-tools';
import { useLocalizedRoute } from 'src/core/utils/routes';
import { useNavbarLinks } from 'src/core/content-config/navbar';
import { useRouter } from 'next/router';
import Container from 'src/components/core/layout/container';
import HamburgerMenu from './hamburger-menu';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState
} from 'react';

import RouterLink from 'src/components/core/links/router-link';
import Sidebar from './sidebar';
import bysteelLogo from 'src/assets/svg/bysteel-logo.svg';
import map from 'lodash/map';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  brochureFile?: string | null
};

/**
 * `Nav` styled component.
 */

const Nav = styled.nav`
  background-color: ${color('white')};
  height: ${theme('dimensions.navbarHeightMobile')}px;
  left: 0;
  padding: ${units(2.5)} 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${theme('zIndex.navbar')};

  ${media.min('md')`
    height: ${theme('dimensions.navbarHeight')}px;
    padding-left: ${theme('dimensions.verticalBar')}px;
  `}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  align-items: center;
  column-gap: ${units(2)};
  display: grid;
  grid-template-areas: 'logo . actions';
  grid-template-columns: auto 1fr auto;
  z-index: 3;
`;

/**
 * `LogoLink` styled component.
 */

const LogoLink = styled(RouterLink)`
  align-items: center;
  display: flex;
  grid-area: logo;
`;

/**
 * `Actions` styled component.
 */

const Actions = styled.div`
  align-items: center;
  display: flex;
  grid-area: actions;
  grid-gap: ${units(3)};

  ${media.min('md')`
    grid-gap: ${units(5.5)};
  `}
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Small)`
  font-weight: 400;
  letter-spacing: 3.57px;
  transition: opacity ${theme('animations.defaultTransition')};
`;

/**
 * `LabelHovered` styled component.
 */

const LabelHovered = styled(Label)`
  font-weight: 700;
  left: 50%;
  opacity: 0;
  position: absolute;
  top: calc(50% + 1.5px);
  transform: translate(-50%, -50%);
  white-space: nowrap;
`;

/**
 * `NavbarLink` styled component.
 */

const NavbarLink = styled(RouterLink)<{ visible?: boolean }>`
  color: ${color('textColor')};
  cursor: pointer;
  line-height: 12px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;

  ${states.action`
    outline: none;
  `}

  &:focus,
  &:hover {
    ${Label} {
      opacity: 0;
    }

    ${LabelHovered} {
      opacity: 1;
    }
  }
`;

/**
 * `StyledSidebar` styled component.
 */

const StyledSidebar = styled(Sidebar)`
  z-index: 1;
`;

/**
 * `Navbar` component.
 */

const Navbar = ({ brochureFile }: Props): ReactElement => {
  const router = useRouter();
  const routeResolver = useLocalizedRoute();
  const navbarLinks = useNavbarLinks();
  const isMobile = useBreakpoint('md', 'max');
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>();
  const toggle = useCallback(() => {
    setSidebarOpen(isSidebarOpen => !isSidebarOpen);
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      if (isSidebarOpen) {
        setTimeout(() => {
          setSidebarOpen(false);
        }, 400);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [isSidebarOpen, router.events]);

  useEffect(() => {
    if (!isSidebarOpen) {
      const body = document.body;

      body.style.overflow = 'visible';
    }
  }, [isSidebarOpen]);

  useBodyScroll({ off: isSidebarOpen });

  return (
    <Nav>
      <StyledContainer>
        <LogoLink
          aria-label={'Home'}
          href={routeResolver('home')}
          locale={router.locale}
        >
          <Svg
            icon={bysteelLogo}
            size={units(19)}
          />
        </LogoLink>

        <Actions>
          {!isMobile ? map(navbarLinks, ({ href, label }) => href && (
            <NavbarLink
              href={href}
              key={label}
              locale={router.locale}
              visible={!isSidebarOpen}
            >
              <Label>
                {label}
              </Label>

              <LabelHovered>
                {label}
              </LabelHovered>
            </NavbarLink>
          )) : (
            <HamburgerMenu
              isOpen={isSidebarOpen}
              onClick={toggle}
            />
          )}
        </Actions>
      </StyledContainer>

      {isMobile && (
        <StyledSidebar
          brochureFile={brochureFile}
          isOpen={isSidebarOpen}
        />
      )}
    </Nav>
  );
};

/**
 * Export `Navbar` component.
 */

export default Navbar;
