
/**
 * Module dependencies.
 */

import {
  Svg,
  color,
  states,
  units
} from '@untile/react-components';

import { ifProp, switchProp, theme } from 'styled-tools';
import { isExternalUrl } from '@untile/react-components/dist/utils';
import Loading from 'src/components/loading';
import React, {
  ElementType,
  FC,
  ReactElement,
  ReactNode,
  forwardRef
} from 'react';

import RouterLink from 'src/components/core/links/router-link';
import get from 'lodash/get';
import styled, { css } from 'styled-components';

/**
 * Export `ButtonColorTheme` type.
 */

export type ButtonColorTheme = 'primary' | 'secondary';

/**
 * Export `ButtonVariant` type.
 */

export type ButtonVariant = 'blank' | 'fill' | 'outline';

/**
 * Export `ButtonProps` interface.
 */

export interface ButtonProps {
  as?: ElementType;
  children?: ReactNode;
  className?: string;
  colorTheme?: ButtonColorTheme;
  disabled?: boolean;
  hasIcon?: boolean;
  hasMargin?: boolean;
  href?: string;
  icon?: string;
  isFullWidth?: boolean;
  isLoading?: boolean;
  isReversed?: boolean;
  onClick?: () => void;
  type?: string;
  variant?: ButtonVariant;
}

/**
 * Color themes.
 */

const colorThemes = {
  primary: {
    blank: {
      bgColor: 'transparent',
      borderColor: 'transparent',
      color: color('green'),
      hoverBgColor: 'transparent',
      hoverBorderColor: 'transparent',
      hoverColor: color.darken('green', 0.1)
    },
    fill: {
      bgColor: color('green'),
      borderColor: color('green'),
      color: color('white'),
      hoverBgColor: color.darken('green', 0.1),
      hoverBorderColor: color.darken('green', 0.1),
      hoverColor: color('white')
    },
    outline: {
      bgColor: color('white'),
      borderColor: color('green'),
      color: color('green'),
      hoverBgColor: color('green'),
      hoverBorderColor: color('green'),
      hoverColor: color('white')
    }
  },
  secondary: {
    blank: {
      bgColor: 'transparent',
      borderColor: 'transparent',
      color: color('black'),
      hoverBgColor: 'transparent',
      hoverBorderColor: 'transparent',
      hoverColor: color('green')
    },
    outline: {
      bgColor: color('white'),
      borderColor: color.transparentize('black', 0.3),
      color: color('black'),
      hoverBgColor: color('black'),
      hoverBorderColor: color('black'),
      hoverColor: color('white')
    }
  }
};

/**
 * Set variant styles.
 */

const setVariantStyles = (themeSchema, variant: ButtonVariant) => css`
  background-color: ${get(themeSchema, [variant, 'bgColor'])};
  border-color: ${get(themeSchema, [variant, 'borderColor'])};
  color: ${get(themeSchema, [variant, 'color'])};

  &:focus,
  &:hover {
    background-color: ${get(themeSchema, [variant, 'hoverBgColor'])};
    border-color: ${get(themeSchema, [variant, 'hoverBorderColor'])};
    color: ${get(themeSchema, [variant, 'hoverColor'])};
  }

  &:disabled {
    background-color: ${get(themeSchema, [variant, 'bgColorDisabled'])};
    border-color: ${get(themeSchema, [variant, 'borderColorDisabled'])};
    color: ${get(themeSchema, [variant, 'colorDisabled'])};
  }
`;

/**
 * Button themes.
 */

const buttonThemes = Object
  .entries(colorThemes)
  .reduce((result, [themeName, themeSchema]) => ({
    ...result,
    [themeName]: switchProp('variant', {
      blank: setVariantStyles(themeSchema, 'blank'),
      fill: setVariantStyles(themeSchema, 'fill'),
      outline: setVariantStyles(themeSchema, 'outline')
    })
  }), {});

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.button.attrs<ButtonProps & { hasIcon: boolean }>(props => {
  const { as, colorTheme, href, type, variant } = props;
  const isExternal = isExternalUrl(href);
  const element = as || href && !isExternal && RouterLink || href && isExternal && 'a' || 'button';

  return {
    as: element,
    colorTheme: colorTheme ?? 'primary',
    type: type || (element === 'button' ? 'button' : null),
    variant: variant ?? 'fill'
  };
})`
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  appearance: none;
  border: 1px solid;
  cursor: pointer;
  display: inline-block;
  outline: none;
  padding: 0;
  position: relative;
  text-transform: lowercase;
  transition: ${theme('animations.defaultTransition')};
  transition-property: background-color, border-color, color, opacity, width;
  white-space: nowrap;

  ${states.action`
    outline: none;
    text-decoration: none;
  `}

  ${switchProp('colorTheme', buttonThemes)}

  ${ifProp('isFullWidth', css`
    justify-content: center;
    width: 100%;
  `, css`
    width: max-content;
  `)}

  ${ifProp('isLoading', css`
    cursor: default;
    opacity: 0.8;
    pointer-events: none;
  `)}

  ${ifProp('disabled', css`
    cursor: default;
    pointer-events: none;
  `)}
`;

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg) <{ isReversed?: boolean }>`
  ${ifProp('isReversed', css`
    margin-left: ${units(2)};
  `, css`
    margin-right: ${units(2)};
  `)}
`;

/**
 * `InnerWrapper` styled component.
 */

const InnerWrapper = styled.span<{
  hasMargin?: boolean
  isLoading?: boolean,
  isReversed?: boolean
}>`
  align-items: center;
  display: flex;
  flex-direction: ${ifProp('isReversed', 'row-reverse', 'row')};
  font-family: ${theme('typography.fontFamily.sansSerif')};
  font-size: 10px;
  font-weight: 400;
  justify-content: center;
  letter-spacing: 2px;
  line-height: 10px;
  min-height: 36px;
  opacity: ${ifProp('isLoading', 0.5, 1)};
  transition: opacity ${theme('animations.defaultTransition')};

  ${ifProp('hasMargin', css`
    padding: 10px ${units(4)};
  `, css `
    padding: 10px 0;
  `)}
`;

/**
 * `StyledLoading` styled component.
 */

const StyledLoading = styled(Loading).attrs({
  relative: true,
  size: 16
}) <{
  hasMargin?: boolean,
  isReversed?: boolean
}>`
  ${ifProp('hasMargin', css`
    ${ifProp('isReversed', css`
      margin: 0 0 0 ${units(1.5)};
    `, css`
      margin: 0 ${units(1.5)} 0 0;
    `)}
  `, css`
      margin: ${units(0.5)};
  `)}
`;

/**
 * `Button` component.
 */

const Button: FC<ButtonProps> = forwardRef<any, ButtonProps>((props: ButtonProps, ref: any): ReactElement => {
  const {
    children,
    colorTheme = 'primary',
    disabled,
    hasMargin = true,
    icon,
    isLoading,
    isReversed,
    ...rest
  } = props;

  return (
    <Wrapper
      colorTheme={colorTheme}
      disabled={disabled || isLoading}
      ref={ref}
      {...rest}
    >
      <InnerWrapper
        hasMargin={hasMargin}
        isLoading={isLoading}
        isReversed={isReversed}
      >
        {isLoading ? (
          <StyledLoading
            active
            hasMargin={!!children}
            isReversed={isReversed}
          />
        ) : icon && (
          <Icon
            icon={icon}
            isReversed={isReversed}
            size={units(1)}
          />
        )}

        {children}
      </InnerWrapper>
    </Wrapper>
  );
});

/**
 * `Button` display name.
 */

Button.displayName = 'Button';

/**
 * Export `Button` component.
 */

export default Button;
