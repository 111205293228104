
/**
 * Module dependencies.
 */

import { ifProp, theme } from 'styled-tools';
import { useBodyScroll } from '@untile/react-components';
import { useRouter } from 'next/router';
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useState
} from 'react';

import styled from 'styled-components';

/**
 * `Animation` type.
 */

type Animation = 'start' | 'end';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ invisible: boolean }>`
  min-height: 100vh;
  opacity: ${ifProp('invisible', 0, 1)};
  position: relative;
  transition: opacity ${theme('animations.easeOutQuadTransition')};
`;

/**
 * `PageTransition` component.
 */

const PageTransition = ({ children }: Props): ReactElement => {
  const router = useRouter();
  const [animation, setAnimation] = useState<Animation>();

  useEffect(() => {
    setAnimation('end');
  }, []);

  useEffect(() => {
    const handleRouteChangeStart = ({ shallow }) => {
      if (shallow) {
        return;
      }

      setAnimation('start');
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router.events, router.pathname]);

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      setAnimation('end');
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.on('routeChangeError', handleRouteChangeComplete);
    };
  }, [router.events]);

  useBodyScroll({ off: animation === 'start' });

  return (
    <Wrapper invisible={animation === 'start'}>
      {children}
    </Wrapper>
  );
};

/**
 * Export `PageTransition` component.
 */

export default PageTransition;
