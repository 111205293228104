
/**
 * Module dependencies.
 */

import { ContactPage } from 'src/types/contact-page';
import { media, useBreakpoint } from '@untile/react-components';
import { theme } from 'styled-tools';
import CookiesBar from 'src/components/cookies-bar';
import Navbar from 'src/components/navbar';
import PageTransition from 'src/components/page-transition';
import React, { ReactElement, ReactNode } from 'react';
import VerticalBar from 'src/components/vertical-bar';
import styled from 'styled-components';
import useContactPage from 'src/api/contact-page/use-contact-page';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  ${media.min('md')`
    padding-left: ${theme('dimensions.verticalBar')}px;
  `}
`;

/**
 * `Layout` component.
 */

const Layout = ({ children }: Props): ReactElement => {
  const isDesktop = useBreakpoint('md', 'min');
  const { data } = useContactPage<ContactPage>();

  return (
    <Wrapper>
      <Navbar brochureFile={data?.brochure} />

      <PageTransition>
        {isDesktop && <VerticalBar brochureFile={data?.brochure} />}

        {children}
      </PageTransition>

      <CookiesBar />
    </Wrapper>
  );
};

/**
 * Export `Layout` component.
 */

export default Layout;
