
/**
 * API endpoints.
 */

export const apiEndpoints = {
  getAbout: '/about-page',
  getBanners: '/banners',
  getBrands: '/brands',
  getCompany: '/company-page',
  getContactPage: '/contact-page',
  getCountryProjects: '/country-projects',
  getHomepage: '/homepage',
  getHomepageProjectDetail: '/homepage-projects/:id',
  getHomepageProjects: '/homepage-projects',
  getLocations: '/locations',
  getNews: '/news',
  getNewsCategories: '/news-categories',
  getNewsDetail: '/news/:slug',
  getProjectCategories: '/project-categories',
  getProjectDetail: '/projects/:slug',
  getProjects: '/projects'
};
