
/**
 * Module dependencies.
 */

import { apiEndpoints } from 'src/core/api-endpoints';
import template from 'lodash/template';

/**
 * `ApiEndpoint` type.
 */

type ApiEndpoint = keyof typeof apiEndpoints;

/**
 * `Interpolations` type.
 */

type Interpolations = {
  [key: string]: string | number
};

/**
 * Template options.
 */

const templateOptions = {
  interpolate: /:([\s\S]+?)\b/g
};

/**
 * Export `getApiEndpoint`.
 */

export function getApiEndpoint(endpointName: ApiEndpoint, interpolations?: Interpolations): string {
  const endpoint = apiEndpoints[endpointName];

  if (interpolations) {
    return template(endpoint, templateOptions)(interpolations);
  }

  return endpoint;
}
