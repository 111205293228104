
/**
 * Module dependencies.
 */

import { keyframes as keyframe } from 'styled-components';

/**
 * `fadeIn` keyframes.
 */

const fadeIn = keyframe`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

/**
 * `fadeOut` keyframes.
 */

const fadeOut = keyframe`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

/**
 * Export `keyframe`.
 */

export const keyframes = {
  fadeIn,
  fadeOut
};
