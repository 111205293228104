
/**
 * Module dependencies.
 */

import { Container as DefaultContainer, media } from '@untile/react-components';
import { ifNotProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * `Container` component.
 */

const Container = styled(DefaultContainer)<{ fluid?: boolean }>`
  padding: 0 ${theme('grid.gutterMobile')}px;

  ${media.min('lg')`
    padding: 0 ${theme('grid.gutter')}px;
  `}

  ${ifNotProp('fluid', css`
    max-width: ${theme('breakpoints.md')}px;

    ${media.min('xxl')`
      max-width: ${theme('breakpoints.xl')}px;
    `}
  `)}
`;

/**
 * Export `Container` component.
 */

export default Container;
