
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    

/**
 * Module dependencies.
 */

import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';
import {
  GlobalStyle as UntileGlobalStyle,
  createTheme
} from '@untile/react-components';

import { theme } from 'src/styles/theme';
import App, { AppContext, AppProps, NextWebVitalsMetric } from 'next/app';
import GlobalStyle from 'src/components/core/global-style';
import GoogleTagManager from 'src/components/core/analytics/google-tag-manager';
import GridDebug from 'src/components/core/debug/grid';
import Head from 'next/head';
import Layout from 'src/components/layout';
import React, { ReactElement } from 'react';
import Script from 'next/script';
import VerticalBarProvider from 'src/context/vertical-bar/provider';
import nookies from 'nookies';
import packageJson from 'package.json';

/**
 * Performance debug.
 */

const isDebug = process.env.NEXT_PUBLIC_IS_DEBUG === 'true';
const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';

/**
 * Query cache.
 */

const queryClient = new QueryClient();

/**
 * Google tag manager dd.
 */

const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

/**
 * Export `reportWebVitals`.
 *
 * Measure the performance of pages .
 */

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (isProduction && isDebug) {
    console.log(metric); // eslint-disable-line no-console
  }
}

/**
 * `PageApp` page.
 */

const PageApp = (props: AppProps & { err: any }): ReactElement => {
  const { Component, err, pageProps } = props;

  return (
    <>
      <Head>
        <meta
          content={'IE=edge'}
          httpEquiv={'X-UA-Compatible'}
        />

        <meta
          content={'text/html;charset=utf-8'}
          httpEquiv={'Content-Type'}
        />

        <meta
          content={'width=device-width, initial-scale=1'}
          name={'viewport'}
        />

        <meta
          content={packageJson.version}
          name={'version'}
        />

        <meta
          content={'true'}
          name={'HandheldFriendly'}
        />

        <link
          href={'/static/favicon/apple-touch-icon.png'}
          rel={'apple-touch-icon'}
          sizes={'180x180'}
        />

        <link
          href={'/static/favicon/favicon-32x32.png'}
          rel={'icon'}
          sizes={'32x32'}
          type={'image/png'}
        />

        <link
          href={'/static/favicon/favicon-16x16.png'}
          rel={'icon'}
          sizes={'16x16'}
          type={'image/png'}
        />

        <link
          href={'/favicon.ico'}
          rel={'shortcut icon'}
          type={'image/x-icon'}
        />

        <link
          href={'/favicon.ico'}
          rel={'icon'}
          type={'image/x-icon'}
        />

        <link
          href={'/site.webmanifest'}
          rel={'manifest'}
        />

        <link
          color={'#5bbad5'}
          href={'/static/favicon/safari-pinned-tab.svg'}
          rel={'mask-icon'}
        />

        <meta
          content={'#da532c'}
          name={'msapplication-TileColor'}
        />

        <meta
          content={'#ffffff'}
          name={'theme-color'}
        />

        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
              // eslint-disable-next-line id-match
            __html: `history.scrollRestoration = "manual"`
          }}
        />
      </Head>

      {isProduction && googleTagManagerId && (
        <Script
          id={'gta'}
          src={`https://www.googletagmanager.com/gtag/js?id=${googleTagManagerId}`}
          strategy={'beforeInteractive'}
        />
      )}

      {isProduction && googleTagManagerId && (
        <Script
          dangerouslySetInnerHTML={{
            // eslint-disable-next-line id-match
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${googleTagManagerId}');
            `
          }}
          id={'gta-script'}
          strategy={'beforeInteractive'}
        />
      )}

      <QueryClientProvider client={queryClient}>
        <Hydrate
          // @ts-ignore
          state={pageProps.dehydratedState}
        >
          <GoogleTagManager>
            <ThemeProvider theme={createTheme(theme)}>
              <VerticalBarProvider>
                <UntileGlobalStyle />

                <GlobalStyle />

                {isDebug && (
                  <GridDebug
                    columns={12}
                    gap={16}
                  />
                )}

                <Layout>
                  <Component
                    {...pageProps}
                    err={err}
                  />
                </Layout>
              </VerticalBarProvider>
            </ThemeProvider>
          </GoogleTagManager>
        </Hydrate>

        <ReactQueryDevtools
          initialIsOpen={process.env.NODE_ENV !== 'production'}
        />
      </QueryClientProvider>
    </>
  );
};

/**
 * Get initial props.
 */

PageApp.getInitialProps = async (appContext: AppContext) => {
  const { ctx, router } = appContext;

  // Set `locale` cookie.
  nookies.set(ctx, 'NEXT_LOCALE', router?.locale, {
    maxAge: 3650 * 24 * 60 * 60,
    path: '/'
  });

  return {
    ...await App.getInitialProps(appContext)
  };
};

/**
 * Export `PageApp` page.
 */

const __Page_Next_Translate__ = PageApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  