
/**
 * Module dependencies.
 */

import {
  Context,
  Dispatch,
  SetStateAction,
  createContext,
  useContext
} from 'react';

/**
 * `VerticalBarProps` type.
 */

type VerticalBarProps = {
  setTitle?: Dispatch<SetStateAction<string | null>>,
  title?: string | null
};

/**
 * Export `VerticalBarContext` context.
 */

export const VerticalBarContext: Context<VerticalBarProps> = createContext({} as VerticalBarProps);

/**
 * Export `useVerticalBarContext` hook.
 */

export const useVerticalBarContext = () => useContext(VerticalBarContext);
