
/**
 * Module dependencies.
 */

import { VerticalBarContext } from './context';
import React, { ReactElement, ReactNode, useState } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode
};

/**
 * `VerticalBarProvider` provider.
 */

const VerticalBarProvider = ({ children }: Props): ReactElement => {
  const [title, setTitle] = useState<string | null>();

  return (
    <VerticalBarContext.Provider
      value={{
        setTitle,
        title
      }}
    >
      {children}
    </VerticalBarContext.Provider>
  );
};

/**
 * Export `VerticalBarProvider` provider.
 */

export default VerticalBarProvider;
